






import Vue from 'vue'
import { userAppConfig } from '@/vuexy/app-config'

export default Vue
  .extend({
    setup() {
      const { containerType } = userAppConfig()

      return {
        containerType,
      }
    },
  })
